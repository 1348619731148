import "./style/style.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "LandingPage";
import Contact from "Contact/Contact";
import Services from "Services/Services";

function App() {
	return (
		<div className="App" id='app-main' >
			<div id='containerx' className="container flex-col">
				<Routes>
					<Route path="/" exact element={<LandingPage />} />
					<Route path="/contact-us" exact element={<Contact />} />
					<Route path="/skills" exact element={<Services />} /> 
				</Routes>
			</div>
		</div>
	);
}

export default App;
