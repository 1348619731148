import gsap from "gsap";
import { Power3 } from "gsap";
import Navbar from "Navbar/Navbar";
import React, { useEffect, useState } from "react";

import BottomSection from "./BottomSection";

const Services = (props) => {
   
    useEffect(()=>{ 

		
		const ctx = gsap.context(() => {
            gsap.fromTo(".navbar", 0.8,{ y: 15, opacity: 0 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
            gsap.from(['.sh1'], {
                duration: 1.2,
                y: 100,
                opacity:0,
                delay: 0.3,
                ease: "power3.inOut",
                stagger: {
                  amount: 0.3,
                },
              });
              gsap.from(['.desc-skills'], {
                duration: 1.2,
                y: 50,
                opacity:0,
                delay: 0.8,
                ease: "power3.inOut",
                stagger: {
                  amount: 0.3,
                },
              });
		})
		
		
		return () =>  ctx.revert()
	}, [])

	const [flag,setFlag] = useState(false)
    
    useEffect(() => {

        if(flag){
            document.getElementsByClassName('navbar')[0].style.position = 'fixed'
            
        }else{
            document.getElementsByClassName('navbar')[0].style.position = 'absolute'
        }

    },[flag])
        


    return (
        <>
            <Navbar setFlag={setFlag} flag={flag}/>
            <div className=" skillsCont container flex-col overflowy" style={{paddingBottom:36}}>
                <div className="services-heading">
                    <div className="sh1">Skills</div>
                    <div className="sh1 child-2">& Hands-on Experience</div>
                    
                </div>
                <div style={{ display: "flex", flex: 1 }}  className="boxes">
                    <div className="box" style={{width:400, margin:'2em 0',textAlign:'center'}}>
                <p className="desc-skills">I'm keen to work with enterprises, I've confidence in my soft skills like Quick & Continuous Learning, Impressive Communication, Thinking Analytically, Problem solving and Debugging. I can work in any team regardless of environment and prefer to go office instead of WFH.
                     I'm a fluent English, Hindi and Gujarati speaker. I've gained all of the skill not by concept but by implementation. Some kills like Kubernetes tried on single VPS. </p>
                </div>
                </div>
                <BottomSection />
            </div>
        </>
    );
};

export default Services;
