import React from "react";

const Heading = (head1) => {
	return (
		<div className="heading">
			<ul>
				 <li>
						<div className="heading1" ><span ref={(el) => (head1 = el)} className="gradient-heading">Pytorch - Tensorflow</span></div>
				</li>
				<li>
						<div className="heading1"><span className="gradient-heading">Scikit-Learn</span></div>
				</li>
				<li>
					<div className="heading1"><span className="pt" >Python - PowerBI</span></div>
				</li>
				{/* <li>
<div className="heading1"><span className="non-gradient">For Your </span><span className="gradient-heading" >Frontend Problems</span></div>
</li> */}
			</ul>
		</div>
	);
};

export default Heading;
