import React, { useEffect,useRef } from "react";
import Breadcrums from "Breadcrums/Breadcrums";
import Heading from "Heading";
import Navbar from "Navbar/Navbar";
import { gsap, Power3 } from "gsap";

const LandingPage = (props) => {

  const head1 = useRef(null)


  useEffect(() => {


    const ctx = gsap.context(()=>{
      // gsap.from([".img-snippet"], {
      //   duration: 0.8,
      //   y: 600,
      //   delay: 0.6,
      //   ease: "power3.inOut",
      //   stagger: {
      //     amount: 0.3,
      //   },
      // });
      gsap.from('.breadcrum-wrapper', {
        duration: 0.8,
        y: 200,
        delay: 0.1,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
     
      gsap.fromTo(".navbar", 0.8,{ y: 15, opacity: 0 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
      gsap.from('.img-snippet', {
        duration: 1.2,
        y: 120,
        opacity:0,
        delay: 0.15,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
      gsap.from('.heading1', {
        duration: 1.2,
        y: 80,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.8,
        },
      });
      gsap.from(['.explore-text'], {
        duration: 1.2,
        x: -100,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
      gsap.from(['.explore-text2'], {
        duration: 1.2,
        x: 100,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
    })
    return () => ctx.revert()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  return (
    <>
      <Navbar />
      <div className="center-section">
        <div className="left-section">
          <Breadcrums />
          <Heading head1 = {head1} />
        </div>
        <div className="right-section">
          <div className="img-snippet" style={{zIndex:1}}>
            <img src={require("./assets/carbon2.png")} alt="snippets"></img>
          </div>
          {/* <div className="description">
          <div className="desc1">
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
            <span style={{ color: "#ffffff" }}>npm i && npm start</span>
            <br />
            <span style={{ color: "#ffffff" }}>. . .</span>
            <br />
            <span style={{ color: "#ffffff", display: "flex" }}>
              Local:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://localhost:
                <span style={{ fontWeight: "bold" }}>3000</span>
              </span>
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>
              {" "}
              On Your Network:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://10.0.0.69:
                <span style={{ fontWeight: "bold" }}>
                  3000
                </span>
              </span>{" "}
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>. . .</span>
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
          </div>
        </div> */}
        </div>
		
        <div className="explore-text">INTELLIGENCE</div>
				<div className="explore-text2">ARTIFICIAL</div>

        {/* <Illustration /> */}
      </div>
	  
    </>
  );
};

export default LandingPage;
