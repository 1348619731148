import React, { useEffect } from "react";
import { gsap, Power3 } from "gsap";
import Navbar from "Navbar/Navbar";

const Contact = (props) => {
	useEffect(() => {
		gsap.fromTo(".navbar", 1.2,{ y: 15, opacity: 0 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
		gsap.fromTo(".head", 0.8,{ y: 200, opacity: 0,delay:0.5 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
		gsap.fromTo(".email", 0.8,{ y: 200, opacity: 0,delay:0.5 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
		gsap.fromTo(".fb-link", 0.6, { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 0.3 });
		gsap.fromTo(".instagram-link", 0.6, { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 1 });
	}, []);

	return (
		<>
			<Navbar />
			<div className="container d-flex center second-section">
				<div className="center-container d-flex flex-col second-inner-section">
					<div style={{ overflow: "hidden" }}>
						<div className="head">CONTACT</div>
					</div>
					<div className="email-div" style={{ overflow: "hidden" }}>
						<div className="email"><a href="mailto:mananclassic@gmail.com">mananclassic@gmail.com</a></div>
					</div>
					<div className="desc-div" style={{ overflow: "hidden" }}>
						<div className="desc">Feel free to contact me!</div>
					</div>
				</div>
				<div className="social-media">
					<div>
						<a className="fb-link" rel="noreferrer" href="https://linkedin.com/in/manan-soni" target="_blank">
							LinkedIn
						</a>
					</div>
					
				</div>
			</div>
		</>
	);
};

export default Contact;
