import { gsap } from "gsap";
import React, { useEffect } from "react";

import { AiOutlineSwapRight } from "react-icons/ai";

const BottomSection = (props) => {
	

	useEffect(()=>{ 
		
		const ctx = gsap.context(() => {
			gsap.from(['.info-box'], {
                duration: 1.2,
                y: 50,
                opacity:0,
                delay: 0.9,
                ease: "power3.inOut",
                stagger: {
                  amount: 0.6,
                },
              });
		})
		return () =>  ctx.revert()
	}, [])


	

	return (
		<>
		<div style={{ display: "flex", flex: 1 }} className="boxes">
			<div className="info-box ib3">
				<p className="small-head">Demonstration</p>
				<div className="info-box-title">Projects</div>
				<p className="info-box-desc" style={{marginTop:36}}> 
				Australia Rain Forecast -> <a href="https://github.com/Mxnxn/Rain_Forecasting_Australia" style={{paddingBottom:'2px'}} className="poppins-highlight">Link</a></p>
				<hr/>
				<p className="info-box-desc">
					Coffee Chain Performance -> <a href="https://github.com/Mxnxn/tableau-coffee-chain-analysis" style={{paddingBottom:'2px'}} className="poppins-highlight">Link</a>
				</p>
				<hr/>
				<p className="info-box-desc">
					I’m onto more exciting ML-AI projects as well as Fullstack
				</p>
				<div className="info-box-link">
					<a href="https://linkedin.com/in/manan-soni" target="_newtab" style={{marginTop:'auto', cursor:'pointer'}}>
						Github <AiOutlineSwapRight />
					</a	>
				</div>
			</div>
			<div className="info-box ib3">
				<p className="small-head">Lastly</p>
				<div className="info-box-title">Databases and ETL</div>
				<p className="info-box-desc" style={{marginTop:36}}> 
				SQL, MySQL, OracleSQL, NoSQL, MongoDB, SSIS (Azure)</p>
				<hr/>
				<p className="info-box-desc">
					For Communication, Teams, Skype, JIRA like boards for tickets management.
				</p>
				<hr/>
				<p className="info-box-desc">
					I’m active on <a href="https://linkedin.com/in/manan-soni" style={{paddingBottom:'2px'}} className="poppins-highlight">Linkedin</a> or just Email!
				</p>
				<div className="info-box-link">
					<a href="https://linkedin.com/in/manan-soni" target="_newtab" style={{marginTop:'auto', cursor:'pointer'}}>
						LinkedIn <AiOutlineSwapRight />
					</a	>
				</div>
			</div>
		</div>
		<div style={{ display: "flex", flex: 1 }} className="boxes">
			<div className="info-box ib1">
			<p className="small-head">Hands-on Experience</p>
				<div className="info-box-title">Python Libraries</div>
				<p className="info-box-desc" style={{marginTop:36}}>
					Pandas, Numpy, Matplotlib, Scikit-Learn, NLTK, Seaborn, Tensorflow, PyTorch, Keras, Huggingface Dataset, transformers, SQLAlchemy, PySpark and more.
				</p>
				<hr />
				<p className="info-box-desc">
					Inferential statistics methods, Correlation, Multi-collinearity, Assotiation Rule, Tableau. 
				</p>
				{/* <div className="info-box-link">
					<Link to="/contact-us">
						Contact <AiOutlineSwapRight />
					</Link>
				</div> */}
			</div>
			<div className="info-box ib2">
				<p className="small-head">Mandatory Requirements</p>
				<div className="info-box-title">Knowledge and Skills</div>
				<p className="info-box-desc" style={{marginTop:36}}> 
				Supervised & Unsupervised problems, Natural Language Processing, BERT/Attention Models, AutoML platforms (Vertex AI).</p>
				<hr/>
				<p className="info-box-desc">
					For Deployment, Dockering, CI/CD (Jenkins, CircleCi), API (flask, Django, FastAPI), Github/Bitbucket, NGInx, Git commands and switching between lines and pull requests.
				</p>
				
				{/* <div className="info-box-link">
					<Link to="/contact-us" style={{marginTop:'auto'}}>
						Contact <AiOutlineSwapRight />
					</Link>
				</div> */}
			</div>
			
		</div>
		
		</>
	);
};

export default BottomSection;
