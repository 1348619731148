import React, { useRef, useEffect, useState } from "react";
import {  Power3, gsap } from "gsap";
import { Link  } from "react-router-dom";
// import { PROJECTS } from "../Constants/Shared";
import {
	staggerText,
	staggerReveal,
	fadeInUp,
	handleHover,
	handleHoverExit,
	// handleCityReturn,
	// handleCity,
	staggerRevealClose,
} from "./Animations";

import { AiOutlineSwapRight, AiOutlineClose, AiOutlineCodepen } from "react-icons/ai";
import CodepenIcon from "./CodepenIcon";

const Navbar = ({flag,setFlag}) => {

	let hoverBtn = useRef(null);
	
	let menuLayer = useRef(null);
	let reveal1 = useRef(null);
	let reveal2 = useRef(null);
	let cityBackground = useRef(null);
	let line1 = useRef(null);
	let line2 = useRef(null);
	let line3 = useRef(null);
	let info = useRef(null);

	const [state, setState] = useState({
		initial: false,
		clicked: null,
		menuName: "Menu",
	});






	useEffect(() => {
		// If the menu is open and we click the menu button to close it.
		if (state.clicked === false) {
			// If menu is closed and we want to open it.

			staggerRevealClose(reveal2, reveal1);
			// Set menu to display none
			gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
			if(typeof(flag) != "undefined"){ setFlag(false) }
			// document.getElementById('skills-cont').style.minHeight = '100vh'
		} else if (state.clicked === true || (state.clicked === true && state.initial === null)) {
			if(typeof(flag) != "undefined"){
				setFlag(true)
			}
			// Set menu to display block
			gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
			//Allow menu to have height of 100%
			gsap.to([reveal1, reveal2], {
				duration: 0,
				opacity: 1,
				height: "100%",
			});
			staggerReveal(reveal1, reveal2);
			fadeInUp(info);
			staggerText(line1, line2, line3);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	// State of our button
	const [disabled, setDisabled] = useState(false);

	// const {navigation} = useContext(UNSAFE_NavigationContext) ;

	// //Use Effect
	// useEffect(() => {
	// 	//Listening for page changes.
	// 	navigation.listen(() => {
	// 		setState({ clicked: false, menuName: "Menu" });
	// 	});
	// }, [navigation]);

	// Toggle menu
	const handleMenu = () => {
		disableMenu();
		if (state.initial === false) {
			setState({
				initial: null,
				clicked: true,
				menuName: "Close",
			});
		} else if (state.clicked === true) {
			setState({
				clicked: !state.clicked,
				menuName: "Menu",
			});
		} else if (state.clicked === false) {
			setState({
				clicked: !state.clicked,
				menuName: "Close",
			});
		}
	};

	//Determine if out menu button should be disabled
	const disableMenu = () => {
		setDisabled(!disabled);
		setTimeout(() => {
			setDisabled(false);
		}, 1500);
		return disabled;
	};

	// useEffect(() => {
	// 	setDisabled(!disabled);
	// 	if (disabled) {
	// 		setTimeout(() => {
	// 			setDisabled(false);
	// 		}, 1500);
	// 		return disabled;
	// 	}
	// 	return disabled;
	// }, [state.clicked]);

	return (
		<>
			<div ref={(el) => (menuLayer = el)} className="hamburger-menu">
				<div ref={(el) => (reveal1 = el)} className="menu-secondary-background-color"></div>
				<div ref={(el) => (reveal2 = el)} className="menu-layer">
					<div ref={(el) => (cityBackground = el)} className="menu-city-background"></div>
					<div className="container jc-center">
						<div className="wrapper">
							<div className="menu-links">
								<nav>
									<ul>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												ref={(el) => (line1 = el)}
												to="/"
												
											>
												Home
											</Link>
										</li>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												ref={(el) => (line2 = el)}
												to="/skills"
											>
												Skills
											</Link>
										</li>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												ref={(el) => (line3 = el)}
												to="/contact-us"
											>
												Contact
											</Link>
										</li>
									</ul>
								</nav>
								<div ref={(el) => (info = el)} className="info">
									<h3>Promise</h3>
									<p>
									I am wholeheartedly committed to continuous learning in the field of AI/ML, where simple neural networks have evolved into advanced attention models like GPT.
									 I dedicate my time and effort to engage in hands-on projects, working with complex datasets that challenge my skills. I actively research techniques for improvement and implementation, starting from the fundamentals and building my way up.
									Furthermore, I understand the importance of thorough documentation throughout the entire process, ensuring a well-rounded final presentation of my work. 
									</p>
								</div>
								{/* <div className="locations">
									Explore :
									{PROJECTS.map((el) => (
										<a
											href={`https://mxnxn.com/${el.url}`}
											rel="noreferrels"
											target="_newtab"
											key={el.name}
											onMouseEnter={() => handleCity(el.image, cityBackground)}
											onMouseOut={() => handleCityReturn(cityBackground)}
										>
											{el.name}
										</a>
									))}
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="navbar">
				<div className="logo">
					<Link to="/" style={{ height: "100%" }}>
						<img src={require('../assets/Vector.png')} alt="logo"></img>
					</Link>
					
				</div>
				
				<div
					className="about menu"
					onClick={() => {
						if (!disabled) {
							handleMenu();
						}
					}}
				>
					
					<div className="menubtn">
						<span >{state.menuName}</span>
						{!state.clicked ? (
							<AiOutlineSwapRight style={{ verticalAlign: "sub",  }} />
						) : (
							<AiOutlineClose style={{ verticalAlign: "sub", height: 25}} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
